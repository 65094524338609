export default class Website {
  constructor() {
    this.init()
  }

  init() {


    document.addEventListener('keydown', (e) => {
      if(e.key == "n"){
        switchMode()
      }
    });
    
  }

}
