const apiUrl = "/track-session";
const ACTIVE_SESSIONS_KEY = 'activeSessionIds';

function getDeviceInfo() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  let deviceType = width >= 1024 ? '🖥️' : '📱';
  return { width, height, deviceType, dimensions: `${width}x${height}` };
}

export const sessionData = {
  currentSlideStartTime: Date.now(),
  sessionId: Date.now(),
  currentSlideName: null,
  currentSlideImage: null,
  portfolioName: document.querySelector('meta[name="portfolio-name"]')?.content || null,
  isIntroSlide: false,
  deviceInfo: getDeviceInfo(),
  lastActivity: Date.now(),
  visitorId: localStorage.getItem('visitorId') || generateVisitorId(),
  isSessionClosed: false,
  lastSlideIndex: 0,
  slideVisits: {}
};

function generateVisitorId() {
  const storedId = localStorage.getItem('visitorId');
  if (storedId) return storedId;
  
  const adjective = ['Swift', 'Silent', 'Curious', 'Bright', 'Gentle'][Math.floor(Math.random() * 5)];
  const noun = ['Fox', 'Owl', 'Wolf', 'Bear', 'Deer'][Math.floor(Math.random() * 5)];
  const number = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  
  const visitorId = `${adjective}${noun}${number}`;
  localStorage.setItem('visitorId', visitorId);
  return visitorId;
}

function getActiveSessions() {
  const sessions = localStorage.getItem(ACTIVE_SESSIONS_KEY);
  return sessions ? JSON.parse(sessions) : [];
}

function addActiveSession(sessionId) {
  const sessions = getActiveSessions();
  sessions.push(sessionId);
  localStorage.setItem(ACTIVE_SESSIONS_KEY, JSON.stringify(sessions));
}

function markSessionClosed(sessionId) {
  const sessions = getActiveSessions();
  const updatedSessions = sessions.filter(id => id !== sessionId);
  localStorage.setItem(ACTIVE_SESSIONS_KEY, JSON.stringify(updatedSessions));
}

function sendData(data) {
    return fetch('/api/track-session', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(result => {
        if (result.status === 'blocked') {
            return false;
        }
        return true;
    })
    .catch(() => false);
}

export function storeSessionData(slideIndex, timeSpent, isClosing = false) {
    const currentSlide = document.querySelector(`.swiper-slide:nth-child(${slideIndex + 1})`);
    if (!currentSlide) {
        return;
    }
    
    if (timeSpent === 0 && !isClosing) {
        return;
    }

    const data = {
        sessionId: sessionData.sessionId,
        slideIndex: slideIndex,
        slideName: currentSlide.dataset.slideName || null,
        slideImage: currentSlide.dataset.slideImage || null,
        timeSpent: timeSpent,
        date: new Date().toISOString(),
        isSessionClosed: isClosing,
        isIntroSlide: currentSlide.classList.contains('intro'),
        portfolioName: sessionData.portfolioName,
        visitorId: sessionData.visitorId,
        deviceInfo: sessionData.deviceInfo,
        lastActivity: Date.now(),
        navigationDirection: slideIndex > sessionData.lastSlideIndex ? 'forward' : 
                           slideIndex < sessionData.lastSlideIndex ? 'backward' : 'same',
        visitCount: (sessionData.slideVisits[slideIndex] || 0) + 1
    };

    sessionData.slideVisits = sessionData.slideVisits || {};
    sessionData.slideVisits[slideIndex] = (sessionData.slideVisits[slideIndex] || 0) + 1;
    sessionData.lastSlideIndex = slideIndex;

    sendData(data);
    
    sessionData.currentSlideStartTime = Date.now();
}

export default class Datatracker {
  constructor() {
    this.lastSlideChange = Date.now();
    this.pendingUpdate = false;
    this.lastUpdateTime = Date.now();
    sessionData.sessionId = Date.now();
    sessionData.currentSlideStartTime = Date.now();
    sessionData.isSessionClosed = false;

    if (!localStorage.getItem('visitorId')) {
      localStorage.setItem('visitorId', sessionData.visitorId);
    }

    this.init();
  }

  init() {
    if (typeof swiper !== 'undefined') {
      swiper.on('slideChangeTransitionStart', () => {
        const now = Date.now();
        if (now - this.lastSlideChange < 100) {
          return;
        }
        
        const timeSpent = now - sessionData.currentSlideStartTime;
        const prevIndex = swiper.previousIndex;

        if (timeSpent > 100) {
          this.pendingUpdate = true;
          storeSessionData(prevIndex, timeSpent, false);
          this.pendingUpdate = false;
          this.lastUpdateTime = now;
        }

        sessionData.currentSlideStartTime = now;
        this.lastSlideChange = now;
      });
    }

    setInterval(() => {
      const now = Date.now();
      
      if (!sessionData.isSessionClosed && 
          document.visibilityState === "visible" && 
          !this.pendingUpdate &&
          (now - this.lastUpdateTime) >= 5000) {
        
        const timeSpent = now - sessionData.currentSlideStartTime;
        
        if (timeSpent > 1000) {
          const activeSlide = document.querySelector('.swiper-slide-active');
          const activeIndex = activeSlide ? 
            Array.from(activeSlide.parentElement.children).indexOf(activeSlide) : 
            (typeof swiper !== 'undefined' ? swiper.activeIndex : 0);
          
          this.pendingUpdate = true;
          storeSessionData(activeIndex, timeSpent, false);
          this.pendingUpdate = false;
          
          sessionData.currentSlideStartTime = now;
          this.lastUpdateTime = now;
        }
      }
    }, 5000);
  }
}

new Datatracker();
