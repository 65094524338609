export default class Index {
  constructor() {
    this.init();
  }

  init() {

    const indexContainer = document.querySelector(".index__container");
    if (typeof (indexContainer) != 'undefined' && indexContainer != null) {

      ///////////////////////////////
      //// Hover and click on project
      ///////////////////////////////

      const indexItem = document.querySelectorAll('.index__item');
      const indexTitle = document.querySelectorAll('.index__item__title');
      const indexCovers = document.querySelectorAll('.index__cover__img');
      const indexGallery = document.querySelector(".index__gallery");
      const indexGalleryProject = document.querySelectorAll('.index__gallery__project');
      const indexDescription = document.querySelectorAll('.index__description');
      const indexDescriptionInner = document.querySelectorAll('.index__description__text');
      var currentIndex = -1;

      indexTitle.forEach(element => {

        const itemIndex = element.dataset.index;

        /// hover on project 

        element.addEventListener("mouseover", function (event) {

          indexCovers.forEach(e => {
            e.classList.remove("index__cover__img--show");
          });

          indexCovers[(parseInt(itemIndex) + 1)].classList.add("index__cover__img--show");
        });

        element.addEventListener("mouseout", function (event) {

          indexCovers.forEach(e => {
            e.classList.remove("index__cover__img--show");
          });

          if(currentIndex == -1){
            indexCovers[0].classList.add("index__cover__img--show");
          }
          else{
            indexCovers[(currentIndex + 1)].classList.add("index__cover__img--show");
          }

        });

        /// click on project

        element.addEventListener('click', function (e) {

          if (currentIndex == itemIndex) {

            /// reset url 

            var stateObj = { home: "home" };
            window.history.pushState(stateObj, "home", "/index");

            /// close gallery

            indexGalleryProject.forEach(e => {
              e.classList.remove("index__gallery__project--show");
            });

            /// close description

            indexDescription.forEach(e => {
              e.setAttribute("style", "height: 0");
            });

            currentIndex = -1;

          }

          else if (currentIndex !== itemIndex || switchProject == "on") {

            /// change url 

            var stateObj = { home: "home" };
            const url = indexItem[itemIndex].dataset.url;
            window.history.pushState(stateObj, "home", "/index?" + url + "");

            /// open gallery

            indexGalleryProject.forEach(e => {
              e.classList.remove("index__gallery__project--show");
            });

            indexGalleryProject[itemIndex].classList.add("index__gallery__project--show");

            indexGallery.scrollLeft = 0;

            /// open description

            const descriptionHeight = indexDescriptionInner[itemIndex].offsetHeight;

            indexDescription.forEach(e => {
              e.setAttribute("style", "height: 0");
            });

            indexDescription[itemIndex].setAttribute("style", "height: " + descriptionHeight + "px");

            currentIndex = itemIndex;

          }


        })
      });

      ////////////////////////
      ////// URL MAGIC
      ////////////////////////

      const currentUrl = window.location.href;

      if (currentUrl.includes("?")) {

        const afterQuestionMark = currentUrl.substring(currentUrl.indexOf('?') + 1);
        const indexItemCurrent = document.querySelector("[data-url='" + afterQuestionMark + "']");
        const urlIndex = indexItemCurrent.dataset.index;

        /// open gallery

        indexGalleryProject.forEach(e => {
          e.classList.remove("index__gallery__project--show");
        });
        indexGalleryProject[urlIndex].classList.add("index__gallery__project--show");

        /// open description

        const descriptionHeight = indexDescriptionInner[urlIndex].offsetHeight;

        indexDescription.forEach(e => {
          e.setAttribute("style", "height: 0");
        });

        indexDescription[urlIndex].setAttribute("style", "height: " + descriptionHeight + "px; transition: none");

        /// update current index

        currentIndex = (parseInt(urlIndex) + 1);

        /// update thumbnail

        indexCovers.forEach(e => {
          e.classList.remove("index__cover__img--show");
        });

        indexCovers[currentIndex].classList.add("index__cover__img--show");


      }

      ///////////////////////////
      /////// DRAG TO SCROLL
      ///////////////////////////

      // let isDown = false;
      // let startX;
      // let scrollLeft;

      // indexGallery.addEventListener("mousedown", e => {
      //   isDown = true;
      //   indexGallery.setAttribute("style", "cursor: grabbing");
      //   startX = e.pageX - indexGallery.offsetLeft;
      //   scrollLeft = indexGallery.scrollLeft;
      // });
      // indexGallery.addEventListener("mouseleave", () => {
      //   isDown = false;
      //   indexGallery.setAttribute("style", "");
      // });
      // indexGallery.addEventListener("mouseup", () => {
      //   isDown = false;
      //   indexGallery.setAttribute("style", "");
      // });
      // indexGallery.addEventListener("mousemove", e => {
      //   if (!isDown) return;
      //   e.preventDefault();
      //   const x = e.pageX - indexGallery.offsetLeft;
      //   const walk = x - startX;
      //   indexGallery.scrollLeft = scrollLeft - walk;
      // });

      ///////////////////////////
      /////// GALLERY NAVIGATION
      ///////////////////////////

      indexGallery.addEventListener('click', function (e) {

        const currentSleft = indexGallery.scrollLeft;

        if(e.clientX > (window.innerWidth / 2)){
          indexGallery.scrollLeft = currentSleft + window.innerWidth;
        }
        else{
          indexGallery.scrollLeft = currentSleft - window.innerWidth;
        }
       
      });

      document.addEventListener('keydown', (e) => {
        console.log(e.key);
        if(e.key == "ArrowRight"){
          const currentSleft = indexGallery.scrollLeft;
        indexGallery.scrollLeft = currentSleft + window.innerWidth;
        }
        if(e.key == "ArrowLeft"){
          const currentSleft = indexGallery.scrollLeft;
          indexGallery.scrollLeft = currentSleft - window.innerWidth;
        }
      });

      ///////////////////////////
      /////// MUTE BUTTON
      ///////////////////////////

      const muteBtn = document.querySelectorAll('.index__gallery__muteBtn');
      const sound = [];

      muteBtn.forEach((element, index) => {

        sound[index] = false;

        element.addEventListener('click', function (e) {

          if (sound[index] == false) {
            element.innerHTML = "Mute";
            sound[index] = true;
            element.nextSibling.nextSibling.muted = false;
            console.log(element.nextSibling.nextSibling);
          }

          else if(sound[index] == true){
            element.innerHTML = "unmute";
            sound[index] = false;
            element.nextSibling.nextSibling.muted = true;
          }

        });

      });

      ////////////////////////////
      ////// Sort items
      ////////////////////////////

      const list = document.querySelector('.index__list');
      const indexSubtitle = document.querySelectorAll('.index__item__subtitle');
      const indexTags = document.querySelectorAll('.index__item__tags');
      const indexCode = document.querySelectorAll('.index__item__code');

      /// Sort by code

      indexCode.forEach(element => {

        element.addEventListener('click', function (e) {

          const divs = [];
          for (var i = 0; i < indexItem.length; ++i) {
            divs.push(indexItem[i]);
          }
          divs.sort(function (a, b) {
            return a.dataset.code.localeCompare(b.dataset.subtitle);
          });

          divs.forEach(function (el) {
            list.appendChild(el);
          });

        });

      });

      /// Sort by subtitle

      indexSubtitle.forEach(element => {

        element.addEventListener('click', function (e) {

          const divs = [];
          for (var i = 0; i < indexItem.length; ++i) {
            divs.push(indexItem[i]);
          }
          divs.sort(function (a, b) {
            return a.dataset.subtitle.localeCompare(b.dataset.subtitle);
          });

          divs.forEach(function (el) {
            list.appendChild(el);
          });

        });

      });

      /// Sort by tags

      indexTags.forEach(element => {

        element.addEventListener('click', function (e) {

          const divs = [];
          for (var i = 0; i < indexItem.length; ++i) {
            divs.push(indexItem[i]);
          }
          divs.sort(function (a, b) {
            return a.dataset.tags.localeCompare(b.dataset.tags);
          });

          divs.forEach(function (el) {
            list.appendChild(el);
          });

        });

      });


    }

  }

}