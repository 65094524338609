import Swiper from "swiper/swiper-bundle.js";
import Loadeer from "loadeer";

import "swiper/swiper.scss";

import { sessionData, storeSessionData } from "./datatracker";

let hasUserInteracted = false;
let instructionTimeout;

// Handle both clicks and arrow key presses
function hideInstructions() {
  if (!hasUserInteracted) {
    hasUserInteracted = true;
    clearTimeout(instructionTimeout);
    
    // Hide instructions if they're showing
    const instructions = document.querySelectorAll('.instruction');
    instructions.forEach(instruction => {
      instruction.classList.remove('show');
    });
  }
}

// Add click handler for the entire document
document.addEventListener('click', hideInstructions);

// Add keyboard handler for arrow keys
document.addEventListener('keydown', function(event) {
  if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
    hideInstructions();
  }
});

// Set timeout for instructions
instructionTimeout = setTimeout(() => {
  if (!hasUserInteracted) {
    const isMobile = window.innerWidth <= 900;
    const instruction = document.querySelector(isMobile ? '.instruction.mobile' : '.instruction.desktop');
    if (instruction) {
      instruction.classList.add('show');
    }
  }
}, 3000); // 6 seconds

export default class Default {
  constructor() {
    this.init();
  }

  init() {
    //// Swiper Portfolio

    if (window.innerWidth < 900) {
      var swiper = new Swiper(".swiper", {
        slidesPerView: 1,
        speed: 0,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          touchStart: function () {
            hideInstructions();
          }
        }
      });
    } else {
      var swiper = new Swiper(".swiper", {
        slidesPerView: 1,
        speed: 0,
        loop: true,
        allowTouchMove: false,
        keyboard: {
          enabled: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }

    const slides = document.querySelectorAll(".swiper-slide");
    let slideActivated = false;

    swiper.on("slideChange", function () {
      const activeSlide = swiper.slides[swiper.activeIndex];
      const headerType = document.querySelector(".header__type");
      const headerClient = document.querySelector(".header__client");
      const headerLogo = document.querySelector(".header__logo");

      if (swiper.activeIndex == 2) {
        slides[1].classList.add("swiper-slide--activated");
        slideActivated = true;
      }

      if (headerType && headerClient && headerLogo) {
        // Clear any pending animations immediately
        if (headerType.currentInterval) {
          clearInterval(headerType.currentInterval);
          headerType.currentInterval = null;
        }
        if (headerClient.currentInterval) {
          clearInterval(headerClient.currentInterval);
          headerClient.currentInterval = null;
        }
        if (headerClient.morphTimeout) {
          clearTimeout(headerClient.morphTimeout);
          headerClient.morphTimeout = null;
        }

        // Rotate logo
        const currentRotation = parseInt(headerLogo.dataset.rotation || 0);
        const newRotation = currentRotation + 360;
        headerLogo.style.transform = `rotate(${newRotation}deg)`;
        headerLogo.dataset.rotation = newRotation;

        // Animate text changes
        const morphText = (element, newText) => {
          if (element.currentInterval) {
            clearInterval(element.currentInterval);
            element.currentInterval = null;
          }

          const oldText = element.textContent.trimEnd();
          const targetText = (newText || "").padEnd(20);
          const maxLength = Math.max(oldText.length, targetText.length);
          let step = 0;

          element.currentInterval = setInterval(() => {
            let newString = "";

            for (let i = 0; i < maxLength; i++) {
              if (i < step) {
                newString += targetText[i] || " ";
              } else {
                newString += oldText[i] || " ";
              }
            }

            element.textContent = newString;
            step++;

            if (step > maxLength) {
              clearInterval(element.currentInterval);
              element.currentInterval = null;
            }
          }, 40);
        };

        // Update and animate header information
        morphText(headerType, activeSlide.dataset.type);
        headerClient.morphTimeout = setTimeout(() => {
          morphText(headerClient, activeSlide.dataset.client);
        }, 200);
      }

      // Existing video autoplay logic
      // const slideContent = activeSlide.querySelector(".swiper__content");
      // if (slideContent) {
      //   const video = slideContent.querySelector(".swiper__content__video");
      //   if (video) {
      //     video.play();
      //   }
      // }

      // Handle active slide video

      const activeLoader = activeSlide.querySelector(".video-loader");

      swiper.slides.forEach((slide) => {
        const loaderPath = slide.querySelector(".loader-path");
        if (loaderPath) {
          loaderPath.classList.remove("animate-circle");
        }
      });

      // Handle active slide video and loader
      const activeVideo = activeSlide.querySelector(".swiper__content__video");
      const activeLoaderPath = activeSlide.querySelector(".loader-path");

      if (activeVideo) {
        // Show loader initially
        if (activeLoader) {
          activeLoader.style.display = "flex";
          if (activeLoaderPath) {
            // Add animation class to trigger the animation
            activeLoaderPath.classList.add("animate-circle");
          }
        }

        // Monitor video states
        const checkReadyState = () => {
          if (activeVideo.readyState === 4) {
            // Video is fully loaded
            if (activeLoader) {
              activeLoader.style.display = "none";
            }
            activeVideo.play();
          }
        };

        activeVideo.addEventListener("loadeddata", checkReadyState);
        activeVideo.addEventListener("canplay", checkReadyState);

        // Check immediate state in case video is already loaded
        checkReadyState();

        // Start loading if needed
        if (activeVideo.readyState === 0) {
          activeVideo.load();
        } else {
          activeVideo.play();
        }
      }

      // Preload next slides
      setTimeout(() => {
        preloadNextSlides(swiper.activeIndex);
      }, 800);

      // Existing session data logic
      const timeSpent = Date.now() - sessionData.currentSlideStartTime;
      storeSessionData(swiper.previousIndex, timeSpent, false);
      sessionData.currentSlideStartTime = Date.now();
    });

    /// Autoplay videos

    function isSafari() {
      return (
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
      );
    }

    const logopreludeVideo = document.querySelector(".logoprelude video");
    const logoprelude = document.querySelector(".logoprelude");

    if (!isSafari() && logopreludeVideo) {
      logopreludeVideo.play();
      logopreludeVideo.addEventListener("ended", function () {
        if (logoprelude) {
          logoprelude.classList.add("logoprelude--fadeout");
        }
      });
    } else if (logoprelude) {
      logoprelude.remove();
    }

    //////////////////////////////
    /////// Rotating logo  ///////
    //////////////////////////////

    var rotation = 0;

    /// swiper

    const logo = document.querySelectorAll(".swiper__logo");

    swiper.on("slideNextTransitionStart", function () {
      rotation = rotation + 360;

      logo.forEach((element) => {
        element.setAttribute("style", "transform: rotate(" + rotation + "deg)");
      });

      const divs = document.querySelectorAll(".swiper__type__line");

      divs.forEach((div) => {
        if (isInViewport(div)) {
          resetAnimation(div);
          revealText(div);
        }
      });
    });

    swiper.on("slidePrevTransitionStart", function () {
      rotation = rotation + 360;

      logo.forEach((element) => {
        element.setAttribute("style", "transform: rotate(" + rotation + "deg)");
      });

      const divs = document.querySelectorAll(".swiper__type__line");

      divs.forEach((div) => {
        if (isInViewport(div)) {
          resetAnimation(div);
          revealText(div);
        }
      });
    });

    /// home

    const home = document.querySelector(".home");

    if (home) {
      document.onclick = function changeContent() {
        rotation = rotation + 360;

        const homeLogo = document.querySelector(".home__logo");
        if (homeLogo) {
          homeLogo.setAttribute(
            "style",
            "transform: rotate(" + rotation + "deg)"
          );
        }
      };
    }

    //// Lazy loading

    const loadeer = new Loadeer();
    loadeer.observe();

    var baseNumber = 375;

    for (let x = 0; x < 37; x++) {
      baseNumber = baseNumber * 1.04;
    }

    // cursor

    const cursor = document.querySelector(".cursor");

    if (cursor) {
      document.addEventListener("mousemove", (e) => {
        cursor.style.left = e.pageX + "px";
        cursor.style.top = e.pageY + "px";

        if (swiper.realIndex === 0 && !slideActivated) {
          cursor.innerHTML = "<p>→</p>";
        } else {
          if (e.pageX > window.innerWidth / 2) {
            cursor.innerHTML = "<p>→</p>";
          } else {
            cursor.innerHTML = "<p>←</p>";
          }
        }
      });
    }

    // Update footer year
    const footerYear = document.querySelector(".footer__year");
    if (footerYear) {
      footerYear.textContent = new Date().getFullYear();
    }

    /// Text animation

    function isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function revealText(div) {
      if (!div.classList.contains("processed")) {
        const contentPieces = div.innerHTML
          .split("<br>")
          .map((piece, index, array) => {
            return index === array.length - 1 && !piece
              ? ""
              : Array.from(piece)
                  .map(
                    (letter) => `<span class="reveal-letter">${letter}</span>`
                  )
                  .join("");
          });

        div.innerHTML = contentPieces.join("<br>");
        div.classList.add("processed");
      }

      const letters = div.querySelectorAll(".reveal-letter");
      letters.forEach((letter, index) => {
        setTimeout(() => {
          letter.style.opacity = "1";
        }, index * 30);
      });
    }

    function resetAnimation(div) {
      const letters = div.querySelectorAll(".reveal-letter");
      letters.forEach((letter) => {
        letter.style.opacity = "0";
      });
    }

    // Add intro text animation

    const introTextPs = document.querySelectorAll(".intro__text p");
    const introTexts = document.querySelectorAll(".intro__text");

    // Add preload function
    function preloadNextSlides(currentIndex) {
      const slidesToPreload = 6;
      const slides = swiper.slides;

      for (let i = 1; i <= slidesToPreload; i++) {
        const nextIndex = (currentIndex + i) % slides.length;
        const nextSlide = slides[nextIndex];

        if (nextSlide) {
          // Find all lazyload elements in the slide
          const lazyElements = nextSlide.querySelectorAll("[data-lazyload]");
          lazyElements.forEach((element) => {
            loadeer.triggerLoad(element);
          });
        }
      }
    }
  }
}
