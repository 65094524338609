import "./_lib/modernizr";
import "../scss/application.scss";

import Datatracker from "./_modules/datatracker";
import Website from "./_modules/website";
import Home from "./_modules/home";
import Default from "./_modules/default";
import Infos from "./_modules/infos";
import Index from "./_modules/index";

new Datatracker();
new Website();
new Home();
new Default();
new Infos();
new Index();