export default class Infos {
  constructor() {
    this.init();
  }

  init() {
    // const infosHeader = document.querySelector(".infos__header");
    // const infosHeaderItem = document.querySelector(".infos__header__item--first");
    // if(typeof(infosHeader) != 'undefined' && infosHeader != null){

    //   document.addEventListener('scroll', function(e) {
    //    if(window.scrollY > 50){
    //     infosHeaderItem.classList.add("infos__header__item--hidden");
    //    }
    //    else{
    //     infosHeaderItem.classList.remove("infos__header__item--hidden");
    //    }
    //   });

    // }
  }
  
}
