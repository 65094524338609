export default class Home {
  constructor() {
    this.init();
  }

  init() {
    // Early return if .home element doesn't exist
    if (!document.querySelector(".home")) {
      return;
    }

    this.initializeTextElements();

    document.fonts.ready.then(() => {
      setTimeout(() => {
        this.setupAnimation();
      }, 100);
    });

    // Get URLs from Kirby fields
    const desktopImages = document.querySelector("[data-showreel-desktop]");

    // Parse the JSON data from data attributes
    const desktopUrls = desktopImages
      ? JSON.parse(desktopImages.dataset.showreelDesktop)
      : [];

    class LogoTurntable {
      constructor(elementId, morphInstance) {
        this.logo = document.getElementById(elementId);
        this.morph = morphInstance;
        this.isGrabbed = false;
        this.startAngle = 0;
        this.currentAngle = 0;
        this.lastAngle = 0;
        this.centerX = 0;
        this.centerY = 0;
        this.velocity = 0;
        this.lastTime = null;
        this.friction = 0.97;
        this.dampening = 0.98;
        this.minVelocity = 0.05;
        this.lastRotation = 0;
        this.isForwardTransition = true;
        this.clickStartTime = 0;
        this.clickStartPosition = { x: 0, y: 0 };
        this.clickThreshold = 5;
        this.spinVelocity = 20;
        this.isMobile = 'ontouchstart' in window;
        this.isSpinning = false;
        
        // Mobile-specific settings
        if (this.isMobile) {
          this.friction = 0.98;
          this.minVelocity = 0.03;
          this.spinVelocity = 25;
        }

        this.init();
      }

      init() {
        this.logo.style.cursor = "grab";
        
        // Modified mousedown to detect click vs drag
        this.logo.addEventListener('mousedown', (e) => {
          this.clickStartTime = Date.now();
          this.clickStartPosition = { x: e.clientX, y: e.clientY };
          this.startRotation(e);
        });

        document.addEventListener('mousemove', (e) => {
          if (this.isGrabbed) {
            // Calculate distance moved
            const distance = Math.hypot(
              e.clientX - this.clickStartPosition.x,
              e.clientY - this.clickStartPosition.y
            );
            
            // If moved more than threshold, it's a drag
            if (distance > this.clickThreshold) {
              this.isSpinning = false;
              this.rotate(e);
            }
          }
        });

        document.addEventListener('mouseup', (e) => {
          const clickDuration = Date.now() - this.clickStartTime;
          const distance = Math.hypot(
            e.clientX - this.clickStartPosition.x,
            e.clientY - this.clickStartPosition.y
          );
          
          // If it's a quick click without much movement
          if (clickDuration < 200 && distance < this.clickThreshold) {
            // Determine spin direction based on click position relative to center
            const rect = this.logo.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const clickX = e.clientX;
            
            // Add to current velocity instead of setting it
            const direction = clickX > centerX ? 1 : -1;
            this.velocity += this.spinVelocity * direction;
            this.isSpinning = true;
          }
          
          this.stopRotation();
        });

        // Touch events
        this.logo.addEventListener('touchstart', (e) => {
          this.clickStartTime = Date.now();
          this.clickStartPosition = { 
            x: e.touches[0].clientX, 
            y: e.touches[0].clientY 
          };
          this.startRotation(e);
        });

        document.addEventListener('touchmove', (e) => {
          if (this.isGrabbed) {
            const distance = Math.hypot(
              e.touches[0].clientX - this.clickStartPosition.x,
              e.touches[0].clientY - this.clickStartPosition.y
            );
            
            if (distance > this.clickThreshold) {
              this.isSpinning = false;
              this.rotate(e);
            }
          }
        });

        document.addEventListener('touchend', (e) => {
          const clickDuration = Date.now() - this.clickStartTime;
          const lastTouch = e.changedTouches[0];
          const distance = Math.hypot(
            lastTouch.clientX - this.clickStartPosition.x,
            lastTouch.clientY - this.clickStartPosition.y
          );
          
          if (clickDuration < 200 && distance < this.clickThreshold) {
            const rect = this.logo.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const clickX = lastTouch.clientX;
            
            // Add to current velocity instead of setting it
            const direction = clickX > centerX ? 1 : -1;
            this.velocity += this.spinVelocity * direction;
            this.isSpinning = true;
          }
          
          this.stopRotation();
        });

        // Move wheel event to document level
        document.addEventListener(
          "wheel",
          (e) => {
            e.preventDefault(); // Prevent default scroll

            // Reduce scroll multiplier for less sensitivity
            const scrollMultiplier = 0.05; // Changed from 0.5 to 0.2
            const deltaRotation = e.deltaY * scrollMultiplier;

            // Update velocity based on scroll
            this.velocity = deltaRotation;

            // Update rotation
            this.currentAngle += deltaRotation;
            this.updateRotation();

            // Update shader progress
            this.updateShaderProgress(deltaRotation);
          },
          { passive: false }
        );

        this.animate();
      }

      startRotation(e) {
        this.isGrabbed = true;
        this.logo.style.cursor = "grabbing";
        
        // Only reset velocity if we're actually starting to drag
        // This allows clicks to add to existing velocity
        const pointer = e.touches ? e.touches[0] : e;
        const startDistance = Math.hypot(
          pointer.clientX - this.clickStartPosition.x,
          pointer.clientY - this.clickStartPosition.y
        );
        
        if (startDistance > this.clickThreshold) {
          this.velocity = 0;
        }

        const rect = this.logo.getBoundingClientRect();
        this.centerX = rect.left + rect.width / 2;
        this.centerY = rect.top + rect.height / 2;

        this.startAngle = this.getAngle(pointer.clientX, pointer.clientY);
        this.lastRotation = this.startAngle;
      }

      rotate(e) {
        if (!this.isGrabbed) return;
        e.preventDefault();

        const pointer = e.touches ? e.touches[0] : e;
        const currentAngle = this.getAngle(pointer.clientX, pointer.clientY);

        // Calculate the shortest rotation distance
        let deltaRotation = currentAngle - this.lastRotation;
        if (deltaRotation > 180) deltaRotation -= 360;
        if (deltaRotation < -180) deltaRotation += 360;

        // Update total rotation and current angle
        this.currentAngle += deltaRotation;

        // Update shader based on actual rotation
        this.updateShaderProgress(deltaRotation);

        // Calculate velocity with increased momentum on mobile
        const newVelocity = deltaRotation * (this.isMobile ? 1.5 : 1);
        this.velocity = this.velocity * 0.8 + newVelocity * 0.2;

        // Store last angle for next frame
        this.lastRotation = currentAngle;

        this.updateRotation();
      }

      updateShaderProgress(rotation) {
        // Convert rotation to progress (negative to match rotation direction)
        const rotationProgress = -rotation / 360;

        // Update progress based on rotation direction
        let newProgress = this.morph.progress + rotationProgress;

        // Removed console.log debug statement

        // Normalize progress and handle image transitions
        if (newProgress > 1) {
          // Moving forward past the end
          this.morph.currentIndex =
            (this.morph.currentIndex + 1) % this.morph.images.length;
          newProgress = newProgress - 1; // Keep the remainder for smooth transition
        } else if (newProgress < 0) {
          // Moving backward past the start
          this.morph.currentIndex =
            (this.morph.currentIndex - 1 + this.morph.images.length) %
            this.morph.images.length;
          newProgress = 1 + newProgress; // Convert negative progress to positive from end
        }

        // Update the shader with normalized progress
        this.morph.updateProgress(newProgress);
      }

      stopRotation() {
        this.isGrabbed = false;
        this.logo.style.cursor = "grab";
      }

      getAngle(x, y) {
        const deltaX = x - this.centerX;
        const deltaY = y - this.centerY;
        return (Math.atan2(deltaY, deltaX) * 180) / Math.PI;
      }

      updateRotation() {
        this.logo.style.transform = `rotate(${this.currentAngle}deg)`;
      }

      animate() {
        if (!this.isGrabbed && Math.abs(this.velocity) > this.minVelocity) {
          // Apply velocity with increased momentum
          const velocityMultiplier = this.isMobile ? 1.2 : 1;
          this.currentAngle += this.velocity * velocityMultiplier;

          // Update shader progress during inertia
          this.updateShaderProgress(this.velocity * velocityMultiplier);

          this.velocity *= this.friction;
          this.updateRotation();
        }
        requestAnimationFrame(() => this.animate());
      }
    }

    // Remove automatic progress from ImageMorph class
    class ImageMorph {
      constructor(canvasId, imageUrls) {
        this.canvas = document.getElementById(canvasId);
        this.ctx = this.canvas.getContext("2d");
        this.images = [];
        this.currentIndex = 0;
        this.progress = 0;

        this.loadImages(imageUrls);
        this.resizeCanvas();
        window.addEventListener("resize", () => this.resizeCanvas());
      }

      loadImages(urls) {
        urls.forEach((url, index) => {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            this.resizeCanvas();
            this.draw();
          };
          img.onerror = (err) => {
            console.error(`Failed to load image ${index}:`, url, err);
          };
          this.images.push(img);
        });
      }

      draw() {
        if (this.images.length < 2) {
          return;
        }

        const currentImg = this.images[this.currentIndex];
        const nextIndex = (this.currentIndex + 1) % this.images.length;
        const nextImg = this.images[nextIndex];

        // Clear canvas
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        // Helper function to draw image with cover positioning and enhanced complex filters
        const drawImageCover = (img, progress, alpha) => {
          const imgRatio = img.width / img.height;
          const canvasRatio = this.canvas.width / this.canvas.height;
          let drawWidth, drawHeight, x, y;

          if (canvasRatio > imgRatio) {
            drawWidth = this.canvas.width;
            drawHeight = this.canvas.width / imgRatio;
            x = 0;
            y = (this.canvas.height - drawHeight) / 2;
          } else {
            drawHeight = this.canvas.height;
            drawWidth = this.canvas.height * imgRatio;
            x = (this.canvas.width - drawWidth) / 2;
            y = 0;
          }

          // Enhanced filter combination
          this.ctx.filter = `
            brightness(${1 + (progress * 4)})
            contrast(${100 + (progress * 150)}%)
            saturate(${100 + (progress * 300)}%)
          `;
          
          this.ctx.globalAlpha = alpha;
          this.ctx.drawImage(img, x, y, drawWidth, drawHeight);
        };

        // Draw current image with enhanced complex transition
        drawImageCover(currentImg, this.progress, 1);

        // Draw next image with inverse enhanced complex transition
        drawImageCover(nextImg, 1 - this.progress, this.progress);
        
        // Reset filter
        this.ctx.filter = 'none';
      }

      updateProgress(progress) {
        this.progress = progress;

        // Removed console.log debug statement for progress update

        // If we've completed a transition, update the current index
        if (this.progress >= 1) {
          this.currentIndex = (this.currentIndex + 1) % this.images.length;
          this.progress = 0;
        } else if (this.progress <= 0) {
          this.currentIndex =
            (this.currentIndex - 1 + this.images.length) % this.images.length;
          this.progress = 1;
        }

        this.draw();
      }

      resizeCanvas() {
        const rect = this.canvas.parentElement.getBoundingClientRect();
        this.canvas.width = rect.width;
        this.canvas.height = rect.height;
        this.draw();
      }
    }

    // Initialize only desktop version
    const imageMorph = new ImageMorph("morphCanvas", desktopUrls);
    const turntable = new LogoTurntable("rotatableLogo", imageMorph);
  }

  initializeTextElements() {
    // Process all text lines
    document.querySelectorAll('.home__line').forEach(line => {
      // Store the original content
      line.dataset.originalContent = line.innerHTML;
      // Clear the visible content initially
      line.innerHTML = '';
    });
  }

  setupAnimation() {
    const trigger = document.querySelector(".home__button");
    const logo = document.querySelector(".header__logo");
    const informations = document.querySelector(".home__informations");
    const services = document.querySelector(".home__services");
    const modalBg = document.querySelectorAll(".home__modalbg");
    const showreel = document.querySelectorAll(".home__showreel");
    const isHomePage = document.querySelector('.home'); // Check if we're on home page

    if (!trigger) return;

    let isShowing = false;

    function createMorphSteps(from, to) {
      const steps = [];
      const fromChars = from.split("");
      const toChars = to.split("");
      const maxLength = Math.max(fromChars.length, toChars.length);

      // If going from INFORMATIONS to CLOSE
      if (from === "INFORMATIONS" && to === "CLOSE") {
        let current = "INFORMATIONS";
        steps.push(current);

        // Build INFORMATIONS one letter at a time
        const transitions = [
          "INFORMATIONS",
          "INFORMATIOSE",
          "INFORMATLOSE",
          "INFORMACLOSE",
          "INFORM CLOSE",
          "INFOR  CLOSE",
          "INFO   CLOSE",
          "INF    CLOSE",
          "IN     CLOSE",
          "I      CLOSE",
          "CLOSE",
        ];

        steps.push(...transitions);
      }
      // If going from CLOSE to INFORMATIONS
      else {
        let current = "CLOSE";
        steps.push(current);

        // Build INFORMATIONS one letter at a time
        const transitions = [
          "CLOSE",
          "CLOSS",
          "CLONS",
          "CIONS",
          "TIONS",
          "ATIONS",
          "MATIONS",
          "RMATIONS",
          "ORMATIONS",
          "FORMATIONS",
          "NFORMATIONS",
          "INFORMATIONS",
        ];

        steps.push(...transitions);
      }

      return steps;
    }

    async function animateText(steps, duration = 25) {
      for (const step of steps) {
        trigger.textContent = step;
        await new Promise((resolve) => setTimeout(resolve, duration));
      }
    }

    // Create a reusable toggle function
    const toggleInformations = async () => {
      const homeInformations = document.querySelector(".home__informations");
      const homeServices = document.querySelector(".home__services");

      homeInformations.classList.remove("home__informations--loaded");
      homeServices.classList.remove("home__services--loaded");

      const stepsToTarget = isShowing
        ? createMorphSteps("CLOSE", "INFORMATIONS")
        : createMorphSteps("INFORMATIONS", "CLOSE");

      // Start text animation
      animateText(stepsToTarget);

      if (!isShowing) {
        // Show animation
        this.animateLetters(true);
        modalBg.forEach(element => {
          element.classList.add("home__modalbg--active");
        });

        showreel.forEach((element) => {
          element.classList.add("home__showreel--active");
        });

        informations.classList.add("home__informations--active");
        services.classList.add("home__services--active");
        logo.classList.add("home__logo--active");
      } else {
        // Hide animation
        this.animateLetters(false);
        modalBg.forEach(element => {
          element.classList.remove("home__modalbg--active");
        });

        showreel.forEach((element) => {
          element.classList.remove("home__showreel--active");
        });

        informations.classList.remove("home__informations--active");
        services.classList.remove("home__services--active");
        logo.classList.remove("home__logo--active");
      }

      isShowing = !isShowing;
    };

    // Add click handler to the button
    trigger.addEventListener("click", toggleInformations);

    // Add click handler to the logo only on home page
    if (isHomePage && logo) {
      logo.addEventListener("click", (e) => {
        e.preventDefault(); // Prevent default link behavior if any
        toggleInformations();
      });
    }
  }

  animateLetters(show) {
    const lines = document.querySelectorAll('.home__line');
    
    // Animation speeds for different sections
    const getDelay = (element) => {
      // Check if the element is within the services section
      if (element.closest('.home__services')) {
        return 30; // Much slower animation for services (increased from 20)
      }
      return 10; // Default animation speed
    };
    
    if (show) {
      const allChars = Array.from(lines).map(line => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(line.dataset.originalContent, 'text/html');
        
        function getTextNodes(node, nodes = []) {
          if (node.nodeType === Node.TEXT_NODE) {
            nodes.push(node);
          } else {
            node.childNodes.forEach(child => getTextNodes(child, nodes));
          }
          return nodes;
        }

        const textNodes = getTextNodes(doc.body);
        const totalLength = textNodes.reduce((sum, node) => sum + node.length, 0);

        return {
          element: line,
          content: line.dataset.originalContent,
          textNodes,
          currentLength: 0,
          totalLength,
          delay: getDelay(line),
          lastUpdate: 0 // Track last update time
        };
      });
      
      const animate = (timestamp) => {
        let anyUpdated = false;
        
        allChars.forEach(lineData => {
          // Only update if enough time has passed based on line's specific delay
          if (lineData.currentLength < lineData.totalLength && 
              timestamp - lineData.lastUpdate > lineData.delay) {
            lineData.currentLength++;
            lineData.lastUpdate = timestamp;
            
            let remainingChars = lineData.currentLength;
            const temp = document.createElement('div');
            temp.innerHTML = lineData.content;
            
            function updateTextContent(node) {
              if (node.nodeType === Node.TEXT_NODE) {
                if (remainingChars >= node.length) {
                  remainingChars -= node.length;
                } else {
                  node.textContent = node.textContent.slice(0, remainingChars);
                  remainingChars = 0;
                }
              } else {
                Array.from(node.childNodes).forEach(updateTextContent);
              }
            }
            
            updateTextContent(temp);
            lineData.element.innerHTML = temp.innerHTML;
            anyUpdated = true;
          }
        });
        
        if (anyUpdated) {
          requestAnimationFrame(animate);
        }
      };
      
      requestAnimationFrame(animate);
    } else {
      const allChars = Array.from(lines).map(line => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(line.dataset.originalContent, 'text/html');
        
        function getTextNodes(node, nodes = []) {
          if (node.nodeType === Node.TEXT_NODE) {
            nodes.push(node);
          } else {
            node.childNodes.forEach(child => getTextNodes(child, nodes));
          }
          return nodes;
        }

        const textNodes = getTextNodes(doc.body);
        const totalLength = textNodes.reduce((sum, node) => sum + node.length, 0);

        return {
          element: line,
          content: line.dataset.originalContent,
          textNodes,
          currentLength: totalLength,
          totalLength,
          delay: getDelay(line),
          lastUpdate: 0
        };
      });
      
      const animate = (timestamp) => {
        let anyUpdated = false;
        
        allChars.forEach(lineData => {
          if (lineData.currentLength > 0 && 
              timestamp - lineData.lastUpdate > lineData.delay) {
            lineData.currentLength--;
            lineData.lastUpdate = timestamp;
            
            if (lineData.currentLength === 0) {
              const temp = document.createElement('div');
              temp.innerHTML = lineData.content;
              const walker = document.createTreeWalker(
                temp,
                NodeFilter.SHOW_TEXT,
                null,
                false
              );
              let node;
              while ((node = walker.nextNode())) {
                node.textContent = '';
              }
              lineData.element.innerHTML = temp.innerHTML;
            } else {
              let remainingChars = lineData.currentLength;
              const temp = document.createElement('div');
              temp.innerHTML = lineData.content;
              
              function updateTextContent(node) {
                if (node.nodeType === Node.TEXT_NODE) {
                  if (remainingChars >= node.length) {
                    remainingChars -= node.length;
                  } else {
                    node.textContent = node.textContent.slice(0, remainingChars);
                    remainingChars = 0;
                  }
                } else {
                  Array.from(node.childNodes).forEach(updateTextContent);
                }
              }
              
              updateTextContent(temp);
              lineData.element.innerHTML = temp.innerHTML;
            }
            
            anyUpdated = true;
          }
        });
        
        if (anyUpdated) {
          requestAnimationFrame(animate);
        }
      };
      
      requestAnimationFrame(animate);
    }
  }
}
